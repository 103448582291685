import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    var header = $("#header");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            header.addClass("-dark");
        } else {
            header.removeClass("-dark");
        }
    });    

    var open = false;

    var openSidebar = function(){
        $('#burgerIcon').addClass('-show');
        $('#burgerDropdown').slideDown();
        open = true;
    }

    var closeSidebar = function(){
        $('#burgerIcon').removeClass('-show');
        $('#burgerDropdown').slideUp();
        open = false;
    }

    $('#hamburger').on('click', function(){
        event.stopPropagation();
        var toggle = open ? closeSidebar : openSidebar;
        toggle();
    });

    $(document).click( function(event){
        if( !$(event.target).closest('#burgerDropdown').length ) {
            closeSidebar();   
        }
    });
});
