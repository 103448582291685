import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('.m-card-text-section:first').addClass('-active');
    $('.m-card-text-content:first').addClass('-active');
    changeTab();
    
    $(window).on('resize', function(){
        changeTab();
    });
});
    
function changeTab() {
    if ($(window).width() > 768) {
        $('.m-card-text-section').on('click', function(){
            $('.m-card-text-section').removeClass('-active');
            $(this).addClass('-active');
            var tab = '#tab' + $(this).attr('data-tab');
            $('.m-card-text-content').removeClass('-active');
            $(tab).addClass('-active');
        });
    } else {
        $('.m-card-text-select').on('change', function (e) {
            var tab = '#tab' + $(this).find(':selected').data('option');
            $('.m-card-text-content').removeClass('-active');
            $(tab).addClass('-active');
        });
    };
}