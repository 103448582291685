import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $('.m-contact-tab:first').addClass('-active');
    $('.m-contact-form:first').addClass('-active');

    $('.m-contact-tab').on('click', function(){
        $('.m-contact-tab').removeClass('-active');
        $(this).addClass('-active');
        var form = '#form' + $(this).attr('data-form');
        $('.m-contact-form').removeClass('-active');
        $(form).addClass('-active');
    });
});