// This file contains all parachute imports, e.g:
import './animation/animation';
import './mmenu/mmenu';
import './header/header';
import './carousel/carousel';
import './carousel/carousel-links';
import './splitting/splitting';
import './news/news';
import './testimonials/testimonials';
import './tabs/contact-tabs';
import './tabs/application.js';
import './carousel/card-carousel';
import './programmes/programmes';
import './programmes/programme';
import './card/card';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
