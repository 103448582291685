import 'jquery';

document.addEventListener(
    "DOMContentLoaded", () => {
        const menu = new Mmenu("#mobileMenu", {
            navbar: {
                title: "MENU",
            },
            setSelected: {
                "hover": true
            },
            offCanvas: {
                position: "left-front"
            }
        },
        {
            offCanvas: {
                clone: false,
                page: {
                    selector: "#page",
                }
            }
        });
    
        const api = menu.API;
        var open = false;

        var openMenu = function(){
            jQuery('body, #header').addClass('-navActive');
            jQuery("#mobileNavButton").addClass("-active");
            api.open();
            open = true;
        };

        var closeMenu = function(){
            jQuery('body, #header').removeClass('-navActive');
            jQuery("#mobileNavButton").removeClass('-active');
            api.close();
            open = false;
        };

        jQuery("#mobileNavButton").click(function (event){
            event.stopPropagation();
            var toggle = open ? closeMenu : openMenu;
            toggle();
        });

        jQuery(".mm-slideout").click(function(event){
            closeMenu(); 
        });
    }
);