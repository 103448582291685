import 'jquery';
import { default as $ } from 'jquery';

$(window).on("load",function() {
    $(window).scroll(function() {
      var pageTop = $(document).scrollTop();
      var windowBottom = pageTop + $(window).height();

      $(".addAnimation").each(function() {
        /* Check the location of each desired element */
        var objectBottom = $(this).offset().top;
        
        /* If the element is completely within bounds of the window, fade it in */
        if (objectBottom < windowBottom) { //object comes into view (scrolling down)
          jQuery(this).addClass('m-fadeIn');
        } else { //object goes out of view (scrolling up)
          jQuery(this).removeClass('m-fadeIn');
        }
      });

      $(".addImageAnimation").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('img-animation');
        } 
        else {
          jQuery(this).removeClass('img-animation');
        }
      });

      $(".addAnimation-button").each(function() {
        var objectBottom = $(this).offset().top;
        
        if (objectBottom < windowBottom) {
          jQuery(this).addClass('m-fadeIn-button');
        }
      });
    }).scroll(); //invoke scroll-handler on page-load
  });
