import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    var programme = $('.m-programme-title').data('programmename');
    
    $('.m-programme-tab').each(function(i, obj) {
        var tab = $(this).data('programme');
        if(programme == tab) {
            $('.m-programme-tab').removeClass('-active');
            $(this).addClass('-active');
        }
    });
});