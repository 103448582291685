import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(document).on('DOMContentLoaded', function() {
    
    $('.m-programmes-list.-isCarousel').slick({
        dots: false,
        arrows: false,
        draggable: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        controls: true,
        autoplay: false,
        centerMode: true,
        centerPadding: 0,
        useAutoplayToggleButton: false,
        rows: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    centerMode: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '120px',
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '40px',
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: 0,
                }
            },
        ]
    });

});